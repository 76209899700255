import { STORAGE_KEY } from '../constants/STORAGE_KEY';
import { AdlCmpStub } from '../services/api';
import { IScriptConfigData } from '../typings/api';
import { isSpider } from './user';

export const checkIsIamFirst = () => {
  const documentScriptList = document.getElementsByTagName('script');

  for (let i = 0, len = documentScriptList.length; i < len; i += 1) {
    const scriptItem = documentScriptList[i];

    if (!/^(.+?)extension:/.test(scriptItem.src)) {
      if (!/stub.js/.test(scriptItem.src) && scriptItem !== document.currentScript) {
        console.warn('CMP stub is not the first in the list');
      }

      break;
    }
  }
};

export const isScriptAvailable = () => {
  return !isSpider && localStorage.getItem(STORAGE_KEY.ADL_IS_CMP_DISABLED) !== 'true';
};

export const checkTestMode = (config: IScriptConfigData) => {
  return (
    !config.isTestModeActive || (config.isTestModeActive && localStorage.getItem(STORAGE_KEY.ADL_CMP_MODE) === 'test')
  );
};

export const getScriptUrl = (hashId: string) => {
  return `${FILE_SERVICE_URL}/${hashId}/script.js`;
};

export const isCmpScriptNode = (node: HTMLElement) => {
  const srcAttr = node.getAttribute('src');

  if (srcAttr && AdlCmpStub) {
    const { hash: scriptHashId } = AdlCmpStub.getScriptAttr();
    const cmpScriptUrl = getScriptUrl(scriptHashId);

    return srcAttr.indexOf(cmpScriptUrl) === 0;
  }

  return false;
};
