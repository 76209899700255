import { CmpApi } from '@iabtechlabtcf/cmpapi';
import * as cmpstub from '@iabtechlabtcf/stub';

import { EVENT_LIST } from '../constants/EVENT_LIST';
import { TEMPLATE_TYPE } from '../constants/TEMPLATE_TYPE';
import { ICMPFilterData, ICmpFilterResult, ICmpStubApi, ICmpStubApiOptions, IScriptConfigData } from '../typings/api';
import { arrayDiff } from '../utils/array';
import { checkTestMode } from '../utils/base';
import { scriptAttr } from '../utils/consent-config';
import { allowAllGoogleConsent } from '../utils/gtag';
import { EventEmitter } from './event-emmiter';
import * as mutationObserver from './mutation-observer';

export class CmpStubApi implements ICmpStubApi {
  protected eventEmitter: EventEmitter;
  scriptVersion = SCRIPT_VERSION;
  updatedAt = UPDATED_AT;
  scriptConfig: IScriptConfigData = {
    templateType: null,
    id: 0,
    isTestModeActive: false,
    showOnSubdomains: false,
    isLoaded: false
  };
  filterData: ICMPFilterData = {
    processed: [],
    allowed: []
  };
  isCMPActive: boolean | null = null;

  constructor(options: ICmpStubApiOptions) {
    this.scriptConfig = options.scriptConfig;

    if (options?.scriptConfig.templateType === TEMPLATE_TYPE.IAB && checkTestMode(this.scriptConfig)) {
      cmpstub();
    }

    this.eventEmitter = new EventEmitter();

    if (options?.filterData) {
      this.filterData = options.filterData;
    }
  }

  getFilterResult(): ICmpFilterResult {
    return {
      processed: this.filterData.processed,
      allowed: this.filterData.allowed,
      rejected: arrayDiff(this.filterData.processed, this.filterData.allowed)
    };
  }

  getScriptAttr() {
    return scriptAttr;
  }

  addEventListener(eventName: string, callback) {
    return this.eventEmitter.subscribe(eventName, callback);
  }

  removeEventListener(eventName: string, callback) {
    this.eventEmitter.unsubscribe(eventName, callback);
  }

  recoveryMutedElements(stopObserve = true, force = false) {
    mutationObserver.recoveryMutedElements(stopObserve, force);
  }

  stop() {
    if (this.isCMPActive === false) {
      return;
    }

    if (window['__tcfapi'] || this.scriptConfig.templateType === TEMPLATE_TYPE.IAB) {
      const iabApiInstance = new CmpApi(CMP_ID, CMP_VERSION, true);

      iabApiInstance.disable();
      delete window['__tcfapi'];
    }

    const tcfapiLocatorFrames = document.querySelectorAll("iframe[name='__tcfapiLocator']")[0];

    if (tcfapiLocatorFrames && tcfapiLocatorFrames.parentElement) {
      tcfapiLocatorFrames.parentElement.removeChild(tcfapiLocatorFrames);
    }

    allowAllGoogleConsent();
    this.recoveryMutedElements(true, true);
    this.isCMPActive = false;
    this.eventEmitter.emit(EVENT_LIST.CMP_UNAVAILABLE);
  }
}
